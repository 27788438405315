<template>
  <div class="ability">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style>
.ability {
  width: 100%;
  height: 100%;
  user-select: none;
}
</style>
